
<div *ngFor="let variable of listVariable">
  <div class="card-mobile mt-3">
    <div class="card-mobile__header d-flex justify-content-between mt-1 mb-2">
      <div class="t-16-700 font-lato color-dark">{{variable.name}}</div>
      <div class="t-14-400 font-lato color-dark">Peso: {{variable.price}}</div>
    </div>
    <div class="card-mobile__body mt-4 mb-4">
      <div class="d-flex  justify-content-between  align-items-center mb-4">
        <div class="t-16-400 font-lato color-dark">Logro:</div>
        <div>
          <milab-number-input 
             class=" w-120" 
             [item]="{ key: variable.id, value: variable.logro}"
             (valueChange)="onInputChange($event)">
          </milab-number-input>
        </div>
      </div>
  
      <div class="d-flex  justify-content-between mb-2">
        <div class="t-16-400 font-lato color-dark">Cumplimiento:</div>
        <div class="t-14-400 font-lato" [ngClass]="variable.cumplimientoColor">
          <div class="vertical-stack">
            {{ variable.cumplimiento }}
            <div class="increase-wrapper mf-5px" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card 
                 [increase]="variable.increase"
                 [icon]="variable.increaseIcon"
                 [color]="variable.increaseColor">
              </milab-increase-card>
            </div>
          </div>
        </div>
      </div>
  
    </div>
    <div class="card-mobile__footer mb-4">
      <div class="d-flex  justify-content-between" (click)="activeTable(variable.id)">
        <div class="t-16-700 font-lato color-dark">Metas a lograr</div>
        <img src="/assets/icons/adn/chevron-down.svg" alt="chevron-down" [ngClass]="{'rotate-180': isTableActive(variable.id)}">
      </div>
      <div *ngIf="isTableActive(variable.id)">                  
          <milab-inverted-table 
            [id]="variable.id" 
            [headers]="variable.metas.headers"
            [content]="variable.metas.content">
          </milab-inverted-table>
      </div>
    </div>
  </div>
</div>
