import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'milab-tab-options',
  templateUrl: './tab-options.component.html',
  styleUrls: ['./tab-options.component.scss']
})
export class TabOptionsComponent {

  @Output() tabChangeButtonEvent = new EventEmitter<string>();

  @Input() tabNameOne: string = 'tabNameOne';
  @Input() tabNameTwo: string = 'tabNameTwo';

  isTabNameOneActive: boolean = true;
  isTabNameTwoActive: boolean = false;
  tabActived: string = '';
  constructor(){ }

  toggleButtons(nameTab: string): void {
    if(this.tabActived !== nameTab){
      if(nameTab === this.tabNameOne){
        this.isTabNameOneActive = true;
        this.isTabNameTwoActive = false;
        this.tabChangeButtonEvent.emit(nameTab);
      }else{
        this.isTabNameOneActive = false;
        this.isTabNameTwoActive = true;
        this.tabChangeButtonEvent.emit(nameTab);
      }
    }
    this.tabActived = nameTab;
  }
}