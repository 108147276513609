import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { formatNumber } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-modal-disbursement',
  templateUrl: './modal-disbursement.component.html',
  styleUrls: ['./modal-disbursement.component.scss']
})
export class ModalDisbursementComponent implements OnInit, OnDestroy {
  @Input() disbursementSelectded = "2122";
  formDisbursement: FormGroup;
  maxInputs = 5; 
  sumResult: number = 0;
  sumInRange: number = 0;
  sumResultString: string = '0';
  sumInRangeString: string = '0';

  error:boolean = false;
  constructor(public activeModal: NgbActiveModal,
             private fb: FormBuilder,
             private adnSimulatorService:AdnSimulatorService,
             private cdr: ChangeDetectorRef) {
    this.formDisbursement = this.fb.group({
      inputs: this.fb.array([this.createInput()])
    });
   }

  ngOnInit(): void {
    this.sumValues();
    window.addEventListener('focusin', this.onKeyboardOpen.bind(this));
    window.addEventListener('focusout', this.onKeyboardClose.bind(this));
  }

  get inputs(): FormArray {
    return this.formDisbursement.get('inputs') as FormArray;
  }
  createInput(): FormGroup {
    return this.fb.group({
      value: ['', [Validators.required, Validators.pattern('^\\d+$')]] 
    });
  }

  removeInput(index: number): void {
    const control = this.inputs.at(index);
    if (control && control.get('value')?.value) {
      this.restValues(parseFloat(control.get('value')?.value));
      this.inputs.removeAt(index);
      this.cdr.detectChanges();
    }
  }

  addInput(): void {
    if (this.inputs.length < this.maxInputs) {
      const firstInput = this.inputs.at(0).get('value')?.value;
      if (firstInput && this.inputs.at(this.inputs.length - 1).get('value')?.value) {
        this.inputs.push(this.createInput());
      }
    }
    this.cdr.detectChanges();
  }


  get iconPlus(): string {
    return this.inputs.length >= this.maxInputs || !this.inputs.at(0).get('value')?.value ? '/assets/icons/adn/plus-grey.svg' : '/assets/icons/adn/plus-green.svg';
  }

  sumValues(): void {
    this.sumResult = this.inputs.controls.reduce((sum, control) => {
      const value = parseFloat(control.value.value);
      return sum + (isNaN(value) ? 0 : value);
    }, 0);

    this.sumResultString = formatNumber(this.sumResult.toString());

    this.sumInRange = this.inputs.controls.reduce((sum, control) => {
      const value = parseFloat(control.value.value);
      return sum + (value >= 1 && value <= 19999 ? value : 0);
    }, 0);
    this.sumInRangeString = formatNumber(this.sumInRange.toString());
  }

  restValues(value:number): void {
    this.sumResult =  this.sumResult -  value;
    this.sumResultString = formatNumber(this.sumResult.toString());
    this.sumInRange = this.sumInRange - (value >= 1 && value <= 19999 ? value : 0);
    this.sumInRangeString = formatNumber(this.sumInRange.toString());
  }

  onInputChange(index: number, event: string): void {
    this.inputs.at(index).get('value')?.setValue(event);
    this.sumValues();
  }

  addAchievement(): void {
    if(this.sumInRange>0){
      const currentValue = this.adnSimulatorService.getAmount20k();
      this.adnSimulatorService.setAmount20k(currentValue+this.sumInRange);
    }

    this.activeModal.close(this.sumResult);
  }
  
  ngOnDestroy() {
    window.removeEventListener('focusin', this.onKeyboardOpen.bind(this));
    window.removeEventListener('focusout', this.onKeyboardClose.bind(this));
  }
  
  onKeyboardOpen() {
    const modalElement = document.querySelector('.modal-content');
    if (modalElement) {
      modalElement.classList.add('keyboard-visible');
    }
  }
  
  onKeyboardClose() {
    const modalElement = document.querySelector('.modal-content');
    if (modalElement) {
      modalElement.classList.remove('keyboard-visible');
    }
  }

}
