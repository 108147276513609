import { Component, Input, OnInit } from '@angular/core';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateBonus, 
         calculateDivisionInput, 
         division, 
         formatNumber, 
         formatPercentageV2, 
         truncateToTwoDecimals } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-bonus-card',
  templateUrl: './bonus-card.component.html',
  styleUrls: ['./bonus-card.component.scss']
})
export class BonusCardComponent implements OnInit {
  @Input() title!: string;
  @Input() amount!: number;
  @Input() advance: number  = 0;
  @Input() activeRv: number = 0;
  @Input() exceeds80: number= 0;
  @Input() amount20k: number = 0;
  @Input() meta20k: number = 0;
  meta20kString: string = '0';
  amount20kString: string = '0';
  iconBonus:string = '';
  weightedCompliance: number = 0;

  increase: string ='';
  valueString: string = '0';
  amountBonus: number = 0;

  constructor(private adnSimulatorService:AdnSimulatorService) { }

  ngOnInit(): void {
    this.valueString = formatPercentageV2(this.amount);
    this.meta20kString = formatNumber(this.meta20k.toString());
    this.amount20kString = formatNumber(this.amount20kString.toString());
    this.iconBonus= (this.exceeds80>0)?'/assets/icons/adn/check-circle.svg':'/assets/icons/adn/x-close.svg';
    this.getBonus();
    this.getPonderado();
  }

  getBonus() {
    this.adnSimulatorService.amount20kSubject$.subscribe((data) => {
      this.amount20k = data;
      this.amount20kString = formatNumber(data.toString());
      this.advance =  calculateDivisionInput(data,this.meta20k);
      this.iconBonus= this.advance>=80?'/assets/icons/adn/check-circle.svg':'/assets/icons/adn/x-close.svg';
      const compliance = this.weightedCompliance>70?1:0;
      const avance = this.advance>=80?1:0;
      const divisionTemp = division(this.amount20k,this.meta20k)*100;
      const bonus = calculateBonus(compliance,avance,divisionTemp);
      this.valueString = truncateToTwoDecimals(bonus);
    });
  }

  getPonderado() {
    this.adnSimulatorService.weightedCompliance$.subscribe((data) => {
      this.weightedCompliance = data;
      const compliance = this.weightedCompliance>70?1:0;
      const avance = this.advance>=80?1:0;
      const divisionTemp = division(this.amount20k,this.meta20k)*100;
      const bonus = calculateBonus(compliance,avance,divisionTemp);
      this.valueString = truncateToTwoDecimals(bonus);
    });
  }
}