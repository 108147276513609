import { Component, Input, OnInit } from '@angular/core';
import { INCREASE } from '@shared/constants/adn-simulator.constants';
import { ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateDirectRemuneration, 
         calculateWeightedCompliance, 
         convertToNumeric, 
         formatNumberWithCommasNoDecimals, 
         formatPercentage, 
         formatPercentageV2 } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-cumplirme-card',
  templateUrl: './cumplirme-card.component.html',
  styleUrls: ['./cumplirme-card.component.scss']
})
export class CumplirmeCardComponent implements OnInit {
  @Input() title!: string;
  @Input() weightedComplianceInitial!: number;
  @Input() description!: string;
  @Input() amountDistributed: number = 0;

  increaseCompliance: string ='';
  increaseDistributed: string ='';
  complianceColor: string = INCREASE.colorIncrement;
  distributedColor: string = INCREASE.colorIncrement;
  iconCompliance: string =INCREASE.iconArrowUp;
  iconDistributede: string =INCREASE.iconArrowUp;

  directRemunerationValue: number = 0;
  directRemunerationInitial: number = 0;
  weightedCompliance: number = 0;
  imgComplianceWeight: string = '/assets/icons/adn/x-close.svg';

  constructor(private adnSimulatorService:AdnSimulatorService) {
    this.adnSimulatorService.metrics$.subscribe((value) => {
      const ponderado = this.calculateWeightedComplianceSync(value);
      calculateWeightedCompliance(ponderado)
        ? (this.imgComplianceWeight = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeight = '/assets/icons/adn/x-close.svg');
      this.updateDirectRemunerationValue(ponderado);
    });
   }



  ngOnInit(): void {
    this.calculateInit();
  }
  calculateInit(){
    const adjustedValue = calculateWeightedCompliance(this.weightedComplianceInitial);
    this.directRemunerationInitial = calculateDirectRemuneration(this.amountDistributed, this.weightedComplianceInitial, adjustedValue);
  }

  private updateDirectRemunerationValue(weightedCompliance: number): void {
    this.weightedCompliance = convertToNumeric(formatPercentageV2(weightedCompliance));
    this.calculeIncremented(weightedCompliance);
      const adjustedValue = calculateWeightedCompliance(weightedCompliance);
      this.directRemunerationValue = calculateDirectRemuneration(this.amountDistributed, weightedCompliance, adjustedValue);
      this.calculeIncrementedRemuneration(this.directRemunerationValue);
    }

    calculeIncremented(weightedCompliance: number): void {
      const increaseTemp = (weightedCompliance-this.weightedComplianceInitial);
      if (increaseTemp > 0) {
        this.increaseCompliance = formatPercentage(increaseTemp);
        this.complianceColor = INCREASE.colorIncrement;
        this.iconCompliance = INCREASE.iconArrowUp;
      } else if (increaseTemp < 0) {
        this.increaseCompliance = formatPercentage(increaseTemp);
        this.complianceColor = INCREASE.colorDecrement;
        this.iconCompliance = INCREASE.iconArrowDown;
      } else {
        this.increaseCompliance = '';
      }
    }

    calculeIncrementedRemuneration(remunerationValue:number): void {
      const increaseTemp = (remunerationValue-this.directRemunerationInitial);
      const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
      if (increaseTemp > 0) {
        this.increaseDistributed = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
        this.distributedColor = INCREASE.colorIncrement;
        this.iconDistributede = INCREASE.iconArrowUp;
      } else if (increaseTemp < 0) {
        this.increaseDistributed = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
        this.distributedColor = INCREASE.colorDecrement;
        this.iconDistributede = INCREASE.iconArrowDown;
      } else {
        this.increaseDistributed = '';
      }
    }

    calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
      return metrics.complianceNetDisbursement.compliance*(metrics.complianceNetDisbursement.weight )+
      metrics.complianceActiveClientStock.compliance*(metrics.complianceActiveClientStock.weight)+
      metrics.complianceLiabilitiesCapture.compliance*(metrics.complianceLiabilitiesCapture.weight )+
      metrics.compliancePriceManagement.compliance*(metrics.compliancePriceManagement.weight )+
      metrics.compliancePise9Mora.compliance*(metrics.compliancePise9Mora.weight)+
      metrics.complianceResolution0Days.compliance*(metrics.complianceResolution0Days.weight);
    }
}
  