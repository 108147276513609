import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subscription, timer } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { distinctUntilChanged, map, mapTo, tap, withLatestFrom } from "rxjs/operators";
import { AdnLoginResponse, LoginResponse } from "@shared/models/login.model";
import { environment } from "@environments/environment";
import { AdnUserProfile } from "@shared/models/UserProfileModel";
import { Router } from "@angular/router";
import { PasswordChange } from "@shared/interfaces";

@Injectable({
    providedIn: 'root',
  })
export class AuthAdnService implements OnDestroy {

  private readonly subscription$ = new Subscription();

  private readonly _isLogged$ = new BehaviorSubject<boolean>(false);
  private readonly _userProfile$ = new BehaviorSubject<AdnUserProfile | null>(null);
  private readonly accessToken$ = new BehaviorSubject<string | null>(null);
  private readonly appToken$ = new BehaviorSubject<string | null>(null);
  private readonly refreshToken$ = new BehaviorSubject<string | null>(null);

  private accessTokenTimer$: Subscription | null = null;
  private appTokenTimer$: Subscription | null = null;

  public readonly userProfile$ = this._userProfile$.asObservable();
  public readonly isLogged$ = this._isLogged$.asObservable();

  private verifyRefresh: boolean = false;

  constructor(private httpClient: HttpClient, private router: Router,private localStorageService: LocalStorageService) {
    (['accessTokenTimer$', 'appTokenTimer$'] as const).forEach((key) =>
    this.subscription$.add(
      this.accessToken$.subscribe((token: string | null) => {
        if (token) {
          // create timers
          const exp = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
          this[key] = timer(exp)
            .pipe(
              withLatestFrom(this._isLogged$),
              map(([_, logged]) => logged)
            )
            .subscribe((logged) => {
              if (!this.localStorageService.get('refresh_token')) {
                const refreshToken = this.localStorageService.get('refresh_token');
                if (refreshToken && !this.verifyRefresh) {
                  this.verifyRefresh = true;
                  this.refreshToken(refreshToken).subscribe(
                    (resp) => {
                      this.localStorageService.set('access_token', resp.access_token);
                      this.localStorageService.set('application_token', resp.application_token);
                      if (resp.refreshToken) {
                        this.localStorageService.set('refresh_token', resp.refreshToken);
                      }
                      setTimeout(() => {
                        this.verifyRefresh = false;
                      }, 10000);
                    },
                    (error) => {
                      this.localStorageService.remove('refresh_token');
                      this.adnCloseSession();
                    }
                  );
                } else {
                  this.adnCloseSession();
                }
              }
            });
        } else if (this[key]) {
          // delete timers
          this[key]?.unsubscribe();
          this[key] = null;
          // !Keep this line commented for testing
          // this.logout();
        }
      })
    )
  );

  this.subscription$.add(
    combineLatest([this.refreshToken$, this.userProfile$])
      .pipe(
        map(
          (values) =>
            values.every(Boolean) &&
            (values.slice(0, 1) as string[]) // only 1 tokens
              .every((token) => !this.tokenExpired(token))
        ),
        distinctUntilChanged()
      )
      .subscribe((s) => this._isLogged$.next(s))
  );
  {
    const user = localStorageService.get('adnUserProfile');
    if (user) {
      const userObj = JSON.parse(user) as AdnUserProfile;
      this._userProfile$.next(this.jsonToAdnUserProfile(userObj));
    }
  }

  this.accessToken$.next(this.localStorageService.get('access_token'));
  this.appToken$.next(this.localStorageService.get('application_token'));
  this.refreshToken$.next(this.localStorageService.get('refresh_token'));
}

private tokenExpired(token: string) {
  const expiry = JSON.parse(atob(token.split('.')[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
}

ngOnDestroy() {
  this.subscription$.unsubscribe();
}

  newLogin(login: any, ipHeader: string = ''): Observable<boolean> {
    const headers = this.headerLoginWithIp(ipHeader,login.recaptcha);

    return this.httpClient
      .post<LoginResponse>(`${environment.apiBaseUrl}/api-identity/public/oauth/adn-token`, login, {
        headers: headers,
      })
      .pipe(
        tap((resp) => {
          this.localStorageService.set('access_token', resp.accessToken);
          this.localStorageService.set('application_token', resp.applicationToken);
          if (resp.refreshToken) {
            this.localStorageService.set('refresh_token', resp.refreshToken);
            this.refreshToken$.next(resp.refreshToken);
          }
          this.accessToken$.next(resp.accessToken);
          this.appToken$.next(resp.applicationToken);
        }),
        mapTo(true)
      );
  }

  getAdnUserProfile(): Observable<AdnUserProfile> {
    return this.httpClient.get<AdnUserProfile>(`${environment.apiBaseUrl}/api-identity/adn`).pipe(
      tap((resp) => {
        this.localStorageService.set('adnUserProfile', JSON.stringify(resp));
        this._userProfile$.next(this.jsonToAdnUserProfile(resp));
      }),
      map((data) => {
        return this.jsonToAdnUserProfile(data) as AdnUserProfile;
      })
    );
  }

  testUserProfile() {
    this.httpClient.get<AdnUserProfile>(`${environment.apiBaseUrl}/api-identity/adn`).subscribe();
  }

  adnRefreshTokenSessionWithoutRedirect(resp: any) {
    const accessToken = resp.access_token ?? resp.accessToken;
    this.accessToken$.next(accessToken);

    const appToken = resp.application_token ?? resp.applicationToken;
    this.appToken$.next(appToken);
    if (resp.refreshToken) {
      this.refreshToken$.next(resp.refreshToken);
    }
  }

  headerLoginWithIp(ipHeader: any, reCaptcha: string) {
    return new HttpHeaders({ 're-captcha-token': reCaptcha, 'Http-Client-Ip': ipHeader });
  }

  headerLoginWithIpv2(ipHeader: any) {
    return new HttpHeaders({ 'Http-Client-Ip': ipHeader });
  }

  headerReCaptcha(reCaptcha: string): any {
    return new HttpHeaders({ 're-captcha-token': reCaptcha });
  }

  adnCloseSession(redirect: string = 'ingresar-adn'): void {
    this.localStorageService.remove('refresh_token');
    sessionStorage.clear();
    this.removeSavedValues();
    this.router.navigate([`/${redirect}`], { queryParamsHandling: 'merge' });
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/api-identity/public/oauth/adn-refresh-token`, { refreshToken });
  }

  
  public updatePassword(data: PasswordChange): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.apiBaseUrl}/api-identity/adn/updatePassword`, data).pipe(mapTo(true));
  }

  removeSavedValues(): void {
    if (!this.localStorageService.get('refresh_token')) {
      (['access_token', 'application_token', 'adnUserProfile'] as const).forEach((key) => {
        this.localStorageService.remove(key);
      });
      ([this.accessToken$, this.appToken$, this.refreshToken$, this._userProfile$] as const).forEach((obs) => {
        obs.next(null);
      });
    }
  }

  private jsonToAdnUserProfile(adn: AdnUserProfile): AdnUserProfile | null {
    return new AdnUserProfile(
      adn.userId,
      adn.typeDocument,
      adn.documentNumber,
      adn.email,
      adn.firstVisit,
      adn.fullName,
      adn.codeAdn
    );
  }
}