<div class="button-container relative-box">
    <button
      type="button" 
      class="position-left center"
      [ngClass]="{ active: isTabNameOneActive, inactive: !isTabNameOneActive }"
      [style.z-index]="isTabNameOneActive ? 2 : 1"
      (click)="toggleButtons(this.tabNameOne)"
    >
      {{tabNameOne}}
    </button>

    <button
      type="button" 
      class="position-right center"
      [ngClass]="{ active: isTabNameTwoActive, inactive: !isTabNameTwoActive }"
      [style.z-index]="isTabNameTwoActive ? 2 : 1"
      (click)="toggleButtons(this.tabNameTwo)"
    >
      {{tabNameTwo}}
    </button>
  </div>