<div class="multipliers-card">
    <div class="multipliers-card__title color-dark">{{ title }}</div>
<div class="multipliers-card_content">
    <div>
        <div class="multipliers-card_content__amount">
            <div class="multipliers-card_content__amount-value color-dark">{{ acceleratorPercent }}%</div>
        </div>
      <div class="increase-wrapper" *ngIf="increaseAccelerator.length > 0">
        <milab-increase-card 
          [increase]="increaseAccelerator"
          [icon]="iconAccelerator"
          [color]="acceleratorColor">
        </milab-increase-card>
      </div>
      <div class="multipliers-card_content__description">
            {{ description1 }}
      </div>
    </div>
    <div class="bd-left pl-2" >
        <div class="multipliers-card_content__amount ">
            <div class="multipliers-card_content__amount-value color-dark">{{ deceleratorPercent }}%</div>
        </div>
        <div class="increase-wrapper" *ngIf="increaseDecelerator.length > 0">
          <milab-increase-card 
            [increase]="increaseDecelerator"
            [icon]="iconDecelerator"
            [color]="deceleratorColor">
          </milab-increase-card>
        </div>
        <div class="multipliers-card_content__description">
            {{ description2 }}
        </div>
    </div>
</div>
  </div>