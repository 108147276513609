<div class="md-summary">
    <div class="md-summary__header">
        <div class="md-summary__header__content">
            <h2 class="md-summary__title">Resumen de tu RV</h2>
           <img src="/assets/icons/adn/close-modal.svg" alt="Cerrar" 
          class="md-summary__close-icon" (click)="activeModal.close()" />
        </div>
    </div>
    <div class="md-summary__body">
        <h2>Cálculo de RV según cumplimiento</h2>
        <div class="summary-card">
            <div class="row-h summary-card__header align-items-center">
              <div class="col-5 t-14-700 font-lato text-left m-0 px-2">Variables directas</div>
              <div class="col-4 t-14-700 font-lato text-center m-0 px-2">Cumplimiento</div>
              <div class="col-3 t-14-700 font-lato text-center m-0 px-2">Peso</div>
            </div>
            <div class="summary-card__body">
              <div class="summary-card__body-item row align-items-center m-0 pd-8-16" *ngFor="let item of data">
                <div class="col-5 t-14-700 font-lato text-left m-0 px-0">{{item.name}}</div>
                <div class="col-4 t-14-700 font-lato text-center m-0 px-0" [ngClass]="item.color">{{item.value}}%</div>
                <div class="col-3 t-14-400 font-lato text-center m-0 px-0">{{item.weight}}%</div>
              </div>
            </div>
            <div class="row summary-card__footer align-items-center m-0 " [ngClass]="bgColorSummary">
                <div class="col-12 summary-card__footer__text m-0 px-2 text-center">Cumplimiento ponderado: 
                    <span class="" > {{weightedCompliance}}%</span> 
                    <img [src]="imgComplianceWeight" alt="check">
                </div>
              </div>
          </div>
    </div>

    <div class="total-card">
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark" [ngClass]="{'has-increase': increaseAmount}">RV por Variables Directas:</div>
            <div class="t-16-700 font-lato color-dark">S/ {{directVariableAmountString}}</div>
            <div class="increase-wrapper" *ngIf="increaseAmount.length > 0"><milab-increase-card 
                   [increase]="increaseAmount"
                   [icon]="increaseAmountIcon"
                   [color]="increaseAmountColor">
                </milab-increase-card>
            </div>
        </div>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">Acelerador: Sobrecumplimiento</div>
            <div class="t-16-700 font-lato color-dark">+{{acceleratorPercentage}}%</div>
        </div>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">Desacelerador: Pase a Recuperaciones</div>
            <div class="t-16-700 font-lato color-dark">+{{deceleratorPercentage}}%</div>
        </div>
        <div class="total-card__content br-top br-button pt-16px  pb-16px">
            <div class="t-16-700 font-lato color-17749B ">RV Final actual:</div>
            <div class="t-16-700 font-lato color-17749B">S/ {{finalRvString}}</div>
        </div>
        <div class="total-card__content pb-16px pt-16px t-16-700 color-17749B">
            <div class="t-16-700 font-lato w-max-205 color-17749B">Bonificación por desembolsos 0 - 20 K:</div>
            <div class="t-16-700 font-lato color-17749B">S/ {{valueString}}</div>
        </div>
        <div class="total-card__footer">
            <div class="t-18-700 font-lato">Total:</div>
            <div class="t-20-800 font-lato">S/ {{finalTotalString}}</div>
        </div>
    </div>
  </div>