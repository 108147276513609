export const INCREASE =  {
    iconArrowUp: '/assets/icons/adn/arrow-narrow-up-green.svg',
    iconArrowDown: '/assets/icons/adn/arrow-down-red.svg',
    colorIncrement:'c-increment',
    colorDecrement:'c-decrement'
  }

export const COMMERCIAL_VALUES =  {
    iconArrowUp: '/assets/icons/adn/arrow-narrow-up-green.svg',
    iconArrowDown: '/assets/icons/adn/arrow-down-red.svg',
    colorIncrement:'c-increment',
    colorDecrement:'c-decrement'
  }

  export const COLLECTION_VALUES =  {
    pise9Id: 'pise9',
    pise9Name: 'Pise 9 (Mora > 8)',
    pise9ActualArrears: 'Mora Real > 8 (S/)',
    pise9PortfolioBalance: 'Saldo de cartera 0-30 (S/)',

    resolutionId: 'resolucion',
    resolutionName: 'Resolución 0 días',
    resolutionResolvedBalance: 'Saldo resuelto 0 días (S/)',
    resolutionOla0dias: 'Ola 0 días (S/)',

  }