<div class="screen screen--min-height screen--bg-gray">
  <div class="screen__content screen__content--padded screen__content--shifted">
    <milab-sidebar [user]="user" *ngIf="!isMobile || sidebarActive " (closeSidebar)="closeSidebar()"></milab-sidebar>

    <div *ngIf="isMobile">
      <milab-adn-header [user]="user" (activedSidebar)="sidebar($event)"></milab-adn-header>
    </div>

    <div class="section section--margin-top" (click)="onScreenClick($event)">
      <router-outlet></router-outlet>
    </div>

    <div class="text text--blue-gray"></div>
  </div>
</div>