<div class="section-info">

    <div class="section-info_content">
        <div class="section-info_content_text">
            <div>
                <div class="d-none d-lg-block section-info_content_text__title">Simulador de Remuneración Variable (RV)
                </div>
                <div class="d-block d-lg-none section-info_content_text__title">Simulador RV</div>
            </div>
            <button (click)="openModal()" class="d-none d-lg-block">Restablecer </button>
            <img class="d-block d-lg-none" (click)="openModal()" src="/assets/img/adn/icons/reverse-left.svg" alt="Restablecer">
        </div>
        <div class="section-info_content__description">
            <img src="/assets/img/adn/icons/clock-rewind.svg" alt="tiempo">
            <div class="section-info_content__description_text">Metas actualizadas {{updatedDate}}</div>
        </div>
    </div>
    <div class="section-info_actions">
        <div class="section-info_actions_info">
            <img class="section-info_actions_info_img" src="/assets/img/adn/icons/sun.svg" alt="sol">
            Días laborados: <span>{{workingDays}} de 30</span>
        </div>

        <div class="section-info_actions_info mt_4">
            <img class="section-info_actions_info_img" src="/assets/img/adn/icons/coins-stacked.svg" alt="dinero">
            Monto a repartir: <span>S/ {{amountDistributed}}</span>
        </div>
    </div>
</div>