<div class="cumplirme_card">
    <div class="cumplirme_card__title">{{ title }}</div>
    <div class="cumplirme_card__amount">
      <div class="cumplirme_card__amount-value color-dark">{{ weightedCompliance }}%</div>
      <img *ngIf="imgComplianceWeight.length>0"
           class="cumplirme_card__amount-icon" 
           [src]="imgComplianceWeight" 
           alt="icon">
    </div>
    <div class="cumplirme_card__increase-wrapper" *ngIf="increaseCompliance.length > 0">
      <milab-increase-card 
         [increase]="increaseCompliance"
         [icon]="iconCompliance"
         [color]="complianceColor"></milab-increase-card>
    </div>

    <div class="cumplirme_card__row">
      <span class="cumplirme_card__label">{{ description }}:</span>
      <div class="cumplirme_card__value">
        <div class="color-dark">S/{{ directRemunerationValue }}</div>
        <div class="increase-distributed" *ngIf="increaseDistributed.length > 0">
          <milab-increase-card 
          [increase]="increaseDistributed"
          [icon]="iconDistributede"
          [color]="distributedColor">
        </milab-increase-card>
        </div>
      </div>
    </div>
  </div>