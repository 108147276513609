import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalSummaryComponent } from '../modal-summary/modal-summary.component';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { Subscription } from 'rxjs';
import { calculateDirectRemuneration, 
         calculateWeightedCompliance, 
         convertToNumeric, 
         formatNumberWithCommas, 
         formatNumberWithCommasNoDecimals,
         formatPercentageV2} from '@shared/utils/adn-simulator.utils';
import { ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { INCREASE } from '@shared/constants/adn-simulator.constants';

@Component({
  selector: 'milab-summary-simulator-card',
  templateUrl: './summary-simulator-card.component.html',
  styleUrls: ['./summary-simulator-card.component.scss']
})
export class SummarySimulatorCardComponent implements OnInit {
  @Input() title!: string;
  @Input() description!: string;
  @Input() amountDistributed: number=0;

  modalRef!: NgbModalRef;
  weightedCompliance: number = 0;
  directRemunerationValue: number = 0;
  @Input() acceleratorPercentage: number = 0;
  @Input() deceleratorPercentage: number= 0;
  finalRv: string = '0';
  finalRvActual: number = 0;
  finalTotal: number = 0;
  bonusO20k: number = 0;
  @Input() meta20k: number = 0;

  @Input() factorAucencia: number = 0;

  increaseSummary: string ='';
  summaryColor: string = INCREASE.colorIncrement;
  iconSummary: string =INCREASE.iconArrowUp;
  
  @Input() finalRvInitial: number = 0;

  constructor(private modalService: NgbModal,
              private adnSimulatorService:AdnSimulatorService) {
                this.adnSimulatorService.metrics$.subscribe((value) => {
                  const ponderado = this.calculateWeightedComplianceSync(value);
                  this.weightedCompliance = ponderado;
                  this.updateDirectRemunerationValue(ponderado);
                });
              }

  private subscription: Subscription = new Subscription();
  ngOnInit(): void {
    this.subscribeSumary();
  }

  private updateDirectRemunerationValue(weightedCompliance: number): void {
      const adjustedValue = calculateWeightedCompliance(weightedCompliance);
      this.directRemunerationValue = calculateDirectRemuneration(this.amountDistributed, weightedCompliance, adjustedValue);
      const calculeFinal = adjustedValue > 0 ?(this.directRemunerationValue*(this.acceleratorPercentage/100)*(1+(this.deceleratorPercentage/100))*this.factorAucencia):0;
      this.finalRvActual = calculeFinal;
      this.finalRv = formatNumberWithCommas(calculeFinal);
      this.calculeIncremented(calculeFinal);
    }

    
    private subscribeSumary(){
      this.subscription.add(
        this.adnSimulatorService.summaryRvSubject$.subscribe((sumarry) => {
          this.acceleratorPercentage = sumarry.acceleratorPercentage;
          this.deceleratorPercentage = sumarry.deceleratorPercentage;
          this.updateDirectRemunerationValue(this.weightedCompliance);
        })
      );
    }


    calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
      return metrics.complianceNetDisbursement.compliance*(metrics.complianceNetDisbursement.weight )+
      metrics.complianceActiveClientStock.compliance*(metrics.complianceActiveClientStock.weight)+
      metrics.complianceLiabilitiesCapture.compliance*(metrics.complianceLiabilitiesCapture.weight )+
      metrics.compliancePriceManagement.compliance*(metrics.compliancePriceManagement.weight )+
      metrics.compliancePise9Mora.compliance*(metrics.compliancePise9Mora.weight)+
      metrics.complianceResolution0Days.compliance*(metrics.complianceResolution0Days.weight);
    }

  openModal() {
    this.modalRef = this.modalService.open(ModalSummaryComponent, { windowClass: 'modal-summary' });
    this.modalRef.componentInstance.directVariableAmount = this.directRemunerationValue;
    this.modalRef.componentInstance.acceleratorPercentage = this.acceleratorPercentage;
    this.modalRef.componentInstance.deceleratorPercentage = this.deceleratorPercentage;
    this.modalRef.componentInstance.finalRv = this.finalRvActual;
    this.modalRef.componentInstance.meta20k = this.meta20k;
  }

  calculeIncremented(finalValue: number): void {
    const increaseTemp = (finalValue - this.finalRvInitial);
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseSummary = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.summaryColor = INCREASE.colorIncrement;
      this.iconSummary = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseSummary = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.summaryColor = INCREASE.colorDecrement;
      this.iconSummary = INCREASE.iconArrowDown;
    } else {
      this.increaseSummary = '';
    }
  }

}
