<div class="md-reset">
    <div class="md-reset__header">
        <img 
          src="/assets/icons/adn/close-modal.svg" 
          alt="Cerrar" 
          class="md-reset__close-icon" 
          (click)="activeModal.close()" />
    </div>
    <div class="md-reset__body">
        <div class="d-flex  justify-content-center ">  
            <img src="/assets/icons/adn/pic-annotation-alert.svg" alt="pic-annotation-alert">
        </div>
     <h2 class="md-reset__body_title mt-2">¿Quieres reestablecer la simulación?</h2>
      <p>De hacerlo, se eliminarán la simulación generada a partir de las modificaciones realizadas. Esta acción es irreversible</p>
    </div>
    <div class="md-reset__footer">
      <button 
        type="button" 
        class="md-reset__footer__volver t-16-700"
        (click)="activeModal.close()">
        Volver
      </button>
      <button 
      type="button" 
      class="md-reset__footer__button t-16-700"
      (click)="activeModal.close(true)">
      Reestablecer
    </button>
    </div>
  </div>