import { Injectable } from '@angular/core';
import { AdnData } from '@shared/interfaces/frontend/adn';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdnCentralizedDataService {
  private readonly INITIAL_KEY = 'initialData';
  private readonly CURRENT_KEY = 'currentData';

  private currentDataSubject = new BehaviorSubject<AdnData | null>(null);
  currentData$: Observable<AdnData | null> = this.currentDataSubject.asObservable();

  constructor() {
    const storedData = this.getFromLocalStorage(this.CURRENT_KEY);
    if (storedData) {
      this.currentDataSubject.next(storedData);
    }
  }

  setInitialData(data: AdnData): void {
    this.saveToLocalStorage(this.INITIAL_KEY, data);
    this.saveToLocalStorage(this.CURRENT_KEY, data);
    this.currentDataSubject.next(data);
  }

  updateCurrentData(updates: Partial<AdnData>): void {
    const currentData = this.currentDataSubject.value || { achievements: [], targets: [] };
    const updatedData = { ...currentData, ...updates };
    this.saveToLocalStorage(this.CURRENT_KEY, updatedData);
    this.currentDataSubject.next(updatedData);
  }

  resetToInitialData(): void {
    const initialData = this.getFromLocalStorage(this.INITIAL_KEY);
    if (initialData) {
      this.currentDataSubject.next(initialData);
      this.saveToLocalStorage(this.CURRENT_KEY, initialData);
    } else {
      console.warn('No initial data found to reset.');
    }
  }

  private saveToLocalStorage(key: string, data: AdnData): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error(`Error saving ${key} to localStorage:`, error);
    }
  }

  private getFromLocalStorage(key: string): AdnData | null {
    try {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error(`Error retrieving ${key} from localStorage:`, error);
      return null;
    }
  }
}
