import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'milab-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.scss']
})
export class CustomerTableComponent implements OnInit {
  @Input() headers: string[] = [];
  @Input() content: any[][] = [];
  @Input() headerBgColor: string = '#17749B'; 
  constructor() { }

  ngOnInit(): void {
  }

}
