<nav class="navigation navigation--transparent navigation--shadowless navigation--rounded">
  <div class="adn-nav">
  <button (click)="sidebar()">
    <img class="adn-nav_image" src="assets/img/adn/icons/menu.svg" alt="logo" class="navigation"/>
  </button>
     <div class="adn-nav_content">
       <div class="adn-nav_content__title" >{{user.fullName}}</div>
       <div class="adn-nav_content__desctiption">Código de matrícula: {{user.codeAdn}}</div>
     </div>
  </div>
</nav>