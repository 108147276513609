import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { INCREASE } from '@shared/constants/adn-simulator.constants';
import { InputItem, Metas, VariableDirect } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateCompliance, calculateComplianceStok, calculateLiabilitiesManagementCompliance, complianceColorValid, convertToNumeric, convertToNumericWithCommas, formatPercentage } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-variable-card-mobile',
  templateUrl: './variable-card-mobile.component.html',
  styleUrls: ['./variable-card-mobile.component.scss']
})
export class VariableCardMobileComponent implements OnInit {
@Input() listVariable:VariableDirect[] =[];
itemDeceleratorsDesktop: InputItem = { key: 'decelerators-desktop', value: 0 };
  isRotated: boolean = false;
  isTable: boolean = false;
@Input() amount: number = 0;
activeVariableId: string | null = null;
isMobile: boolean = window.innerWidth <= 768;
constructor(private adnSimulatorService: AdnSimulatorService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.updateIsMobile();
    window.addEventListener('resize', this.updateIsMobile);
  }

  activeTable(id: string): void {
    this.activeVariableId = this.activeVariableId === id ? null : id;
  }

  isTableActive(id: string): boolean {
    return this.activeVariableId === id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amount && changes.amount.currentValue) {
      if (this.isMobile) {
        this.updateLogroFromOutside('neto', changes.amount.currentValue);
      }  
      this.amount= 0;
    }
   }
  public updateLogroFromOutside(id: string, value: number): void {
    const variable = this.listVariable.find(item => item.id === id);
    if (variable) {
      variable.logro = (parseFloat(variable.logro.toString()) + value);
      const [minDisbursement, disbursement100, disbursement150, disbursement200] = variable.metas.content[0].map(convertToNumericWithCommas);
      const dataCumplimiento = calculateCompliance(
        variable.logro,
        minDisbursement,
        disbursement100,
        disbursement150,
        disbursement200
      );
      this.updateIncrease(variable, dataCumplimiento);
      variable.cumplimiento = dataCumplimiento.toString().concat('%');
      variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      if (variable.id === 'neto') {
        this.adnSimulatorService.updateComplianceNetDisbursement(convertToNumeric(dataCumplimiento));
      }
  
      this.cdr.detectChanges();
    }
  }

  onInputChange(event: InputItem): void {
    const variable = this.listVariable.find(item => item.id === event.key);
    
    if (variable && variable.metas) {
      variable.logro = event.value;


      if (variable.id === 'neto') {
        const [minDisbursement, disbursement100, disbursement150, disbursement200] = variable.metas.content[0].map(convertToNumericWithCommas);
        const dataCumplimiento = calculateCompliance(
          convertToNumeric(event.value),
          minDisbursement,
          disbursement100,
          disbursement150,
          disbursement200
        );

        this.updateIncrease(variable, dataCumplimiento);
        this.adnSimulatorService.updateComplianceNetDisbursement(convertToNumeric(dataCumplimiento));
        variable.cumplimiento = dataCumplimiento.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      }
      if (variable.id === 'stocks') {
        const [minClients, clients100, clients150, clients200] = variable.metas.content[0].map(convertToNumericWithCommas);
        const dataCumplimientoStock = calculateComplianceStok(
          convertToNumeric(event.value),
          minClients,
          clients100,
          clients150,
          clients200
        );
        this.updateIncrease(variable, dataCumplimientoStock);
        this.adnSimulatorService.updateComplianceActiveClientStock(convertToNumeric(dataCumplimientoStock));
        variable.cumplimiento = dataCumplimientoStock.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimientoStock));
      }
      if (variable.id === 'pasivos') {
        const [minLiabilities, liabilities100, liabilities150, liabilities200,liabilities300,liabilities400] = variable.metas.content[0].map(convertToNumericWithCommas);
        const dataCumplimientoPasive = calculateLiabilitiesManagementCompliance(
          convertToNumeric(event.value),
          minLiabilities,
          liabilities100,
          liabilities150,
          liabilities200,
          liabilities300,
          liabilities400
        );
        this.updateIncrease(variable, dataCumplimientoPasive);
        this.adnSimulatorService.updateComplianceLiabilitiesCapture(convertToNumeric(dataCumplimientoPasive));
        variable.cumplimiento = dataCumplimientoPasive.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimientoPasive));
      }
      this.cdr.detectChanges();
    }
   }


   
  private updateIncrease(variable: VariableDirect, dataCumplimiento: number): void {
    const increase = dataCumplimiento - variable.cumplimientoInit;
    if (increase > 0) {
      variable.increase = formatPercentage(increase);
      variable.increaseColor = INCREASE.colorIncrement;
      variable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increase < 0) {
      variable.increase = formatPercentage(increase);
      variable.increaseColor = INCREASE.colorDecrement;
      variable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      variable.increase = '';
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateIsMobile);
  }

  updateIsMobile = (): void => {
    this.isMobile = window.innerWidth <= 768;
  }


}
