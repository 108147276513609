import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountNumericFormatter'
})
export class AmountNumericFormatterPipe implements PipeTransform {

  transform(value: any, decimalPlaces: number = 2): any {
    if (value === null || value === undefined || typeof value !== 'string' && typeof value !== 'number') {
      return value;
    }

    let inputValue = value.toString();

    let cleanValue = inputValue.replace(/[^0-9.]/g, '');

    const dotCount = (cleanValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      cleanValue = cleanValue.replace(/\.+/, '.');
    }

    const parts = cleanValue.split('.');
    if (parts.length > 1 && parts[1].length > decimalPlaces) {
      parts[1] = parts[1].substring(0, decimalPlaces);
      cleanValue = parts.join('.');
    }

    if (cleanValue.startsWith('.') && cleanValue !== ".") {
      cleanValue = "0" + cleanValue;
    }

    if (cleanValue.startsWith('0') && cleanValue.length > 1 && !cleanValue.startsWith('0.')) {
      cleanValue = cleanValue.replace(/^0+/, '');
    }

    let formattedValue = '';
    const integerPart = cleanValue.split('.')[0];

    if (integerPart) {
      formattedValue = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    if (cleanValue.includes('.')) {
      formattedValue += '.' + cleanValue.split('.')[1];
    }

    return formattedValue;
  }

}
