<div class="section-variables-collection">
  <table class="card-variables-table">
    <thead>
      <tr>
        <th class="w-14 text-left ">{{variableItem}}</th>
        <th class="w-10">Peso</th>
        <th class="w-13">Logro</th>
        <th class="w-13">Cumplimiento</th>
        <th class="w-50">Metas</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let variable of listVariablePise9">
        <td class="w-14 text-left td-title color-dark">{{ variable.name }}</td>
        <td class="w-10">{{ variable.price }}</td>
        <td class="w-13"> {{ variable.logro }}%</td>
        <td class="w-13" [ngClass]="variable.cumplimientoColor">
          <div class="vertical-stack">
            {{ variable.cumplimiento }}
            <div class="increase-wrapper" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card 
                 [increase]="variable.increase"
                 [icon]="variable.increaseIcon"
                 [color]="variable.increaseColor">
              </milab-increase-card>
            </div>
          </div>
        </td>
        <td class="w-50">
          <milab-customer-table class="section-table" [headers]="variable.metas.headers"
            [content]="variable.metas.content" headerBgColor="#17749B">
          </milab-customer-table>
        </td>
      </tr>
      <tr class="bor-bottom">
        <td colspan="5" class="bor-bottom" >
          <milab-management-collections-card 
          [itemLabelOne]="managementItem.labelOne"
          [itemLabelTwo]="managementItem.labelTwo"
          [valueTwo]="managementItem.valueTwo" 
          [valueOne]="managementItem.valueOne"
          key="pise9"
          (managementValueChange)="onManagementPise9Change($event)">
          </milab-management-collections-card>
        </td>
      </tr>
      <tr *ngFor="let variable of listVariableResolutions">
        <td class="w-14 text-left td-title">{{ variable.name }}</td>
        <td class="w-10">{{ variable.price }}</td>
        <td class="w-13"> {{ variable.logro }}%</td>
        <td class="w-13" [ngClass]="variable.cumplimientoColor">
          <div class="vertical-stack">
            {{ variable.cumplimiento }}
            <div class="increase-wrapper" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card 
                 [increase]="variable.increase"
                 [icon]="variable.increaseIcon"
                 [color]="variable.increaseColor">
              </milab-increase-card>
            </div>
          </div>
        </td>
        <td class="w-50">
          <milab-customer-table class="section-table" [headers]="variable.metas.headers"
            [content]="variable.metas.content" headerBgColor="#17749B">
          </milab-customer-table>
        </td>
      </tr>
      <tr>
        <td colspan="5">
          <milab-management-collections-card 
          [itemLabelOne]="managementItemTwo.labelOne"
          [itemLabelTwo]="managementItemTwo.labelTwo"
          [valueTwo]="managementItemTwo.valueTwo" 
          [valueOne]="managementItemTwo.valueOne"
          key="resolucion"
          (managementValueChange)="onManagementResolutionsChange($event)">
          </milab-management-collections-card></td>
      </tr>
    </tbody>
  </table>


</div>