import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdnAchievement, AdnTarget } from '@shared/interfaces/frontend/adn';
import { AdnUserProfile } from '@shared/models/UserProfileModel';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'milab-adn-dashboard',
  templateUrl: './adn-dashboard.component.html',
  styleUrls: ['./adn-dashboard.component.scss']
})
export class AdnDashboardComponent implements OnInit {
  isMobile: boolean = false;
  user!: AdnUserProfile;
  sidebarActive = false;
  actionSidebar = false;

  targets: AdnTarget[] = [];
  achievements: AdnAchievement[] = [];

  constructor(private localStorageService: LocalStorageService,
              private router: Router,
              private adnSimulatorService:AdnSimulatorService) { }

  ngOnInit(): void {
    this.checkIfMobile();
    this.getUser();
  }

  getUser(){

    const adnUserProfile = this.localStorageService.get('adnUserProfile');
    if (adnUserProfile) {
      this.user = JSON.parse(adnUserProfile) as AdnUserProfile;
      this.getAllData(this.user.codeAdn);
    }else {
      this.router.navigate(['/ingresar-adn']);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkIfMobile();
  }

  checkIfMobile(): void {
    this.isMobile = window.innerWidth < 768;
  }

  sidebar(active:boolean){
    this.sidebarActive = active;
    this.actionSidebar = true;
  }

  onScreenClick(event: MouseEvent): void {
    if (this.sidebarActive && this.actionSidebar) {
      this.sidebarActive = false;
    }
  }

  closeSidebar(): void {
    this.sidebarActive = false;
  }

  getAllData(code: string): void {
    this.adnSimulatorService.loadInitialData(code).subscribe({
      next: (data) => {
        this.achievements = data.achievements;
        this.targets = data.targets;
        console.log('Achievements:', data.achievements); // Mostrar log de achievements
        console.log('Goals:', data.targets); // Mostrar log de goals
      },
      error: (err) => console.error('Error loading initial data', err),
    });
  }
}
