import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { formatNumber } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-disbursement-input',
  templateUrl: './disbursement-input.component.html',
  styleUrls: ['./disbursement-input.component.scss']
})
export class DisbursementInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() type: string = '';
  @Input() isPassword: boolean = false;
  @Input() dynamicId: string = 'custom-default';
  @Input() textLabel: string = '';
  @Input() value: string = '';
  @Output() inputChangeString = new EventEmitter<string>();
  @Input() error: boolean = false;

  inputValue: string = '';
  inputFocused: boolean = false;
  showPassword: boolean = false;
  imgPassword: string = 'assets/img/adn/eye-off.svg';

  realValue: string = '';
  previousDisplayValue: string = ''; 

  constructor() { }

  ngOnInit(): void {
    this.preloadImages();
  }

  preloadImages(): void {
    const images = ['assets/img/adn/eye.svg', 'assets/img/adn/eye-off.svg'];
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }


  onFocus() {
    this.inputFocused = true;
  }

  onBlur() {
    this.inputFocused = false;
  }

  
  ngOnChanges(changes: SimpleChanges) {
    if(this.textLabel && this.textLabel != '') {
      this.inputValue= this.textLabel;
    }
    if (changes.value) {
      this.inputValue = formatNumber(changes.value.currentValue);
    }
    if (changes.error) {
      this.error = changes.error.currentValue;
    }
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;
  
      if (!/^\d*\.?\d*$/.test(value)) {
        value = value.replace(/[^0-9.]/g, '');  
      }
  
      const parts = value.split('.');
      if (parts.length > 2) {
        value = `${parts[0]}.${parts[1]}`;
      }
  
      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }

      if (parts[0].startsWith('0') && !value.includes('.')) {
        value = parts[0].replace(/^0+/, '') || '0';
      }
    
    inputElement.value = formatNumber(value);
    this.inputValue = formatNumber(value);;
    this.inputChangeString.emit(value); 
  }



  toggleShowPassword(): void {
    this.showPassword =!this.showPassword;
    this.imgPassword = this.showPassword?'assets/img/adn/eye.svg':'assets/img/adn/eye-off.svg';
    this.type = this.type === 'password' ? 'text' : 'password';
  }

}