<form>
  <div class="md-disbursement">
    <div class="md-disbursement__header">
        <h2 class="md-disbursement__title">Nuevo desembolso</h2>
        <img 
          src="/assets/icons/adn/close-modal.svg" 
          alt="Cerrar" 
          class="md-disbursement__close-icon" 
          (click)="activeModal.close()" />
    </div>
    <div class="md-disbursement__body">
      <p class="t-16-400 font-lato color-dark">Ingresa el monto que estimas añadir a tu logro.</p>
      <div class="md-disbursement__body__imput-content mb-3" *ngFor="let input of inputs.controls; let i = index" [formGroupName]="i">
        <div class="w-100" >
            <milab-disbursement-input
            class="mb-1" 
            type="text"
            dynamicId="disbursement-{{ i }}" 
            [inputValue]="input.value.value"
            label="Desembolso unitario (S/)"  
            [error]="error"
            (inputChangeString)="inputs.at(i).get('value')?.setValue($event)"
            (inputChangeString)="onInputChange(i, $event)">
         </milab-disbursement-input>
        </div>
        <div>
            <button  class="btn-delete" 
                     (click)="removeInput(i)" 
                     [disabled]="inputs.length === 1 || !inputs.at(i).get('value')?.value">
                     <ng-container *ngIf="inputs.length > 1 && inputs.at(i).get('value')?.value">
                       <img src="/assets/icons/adn/trash.svg" alt="Eliminar" class="md-disbursement__icon" />
                     </ng-container>
                     <ng-container *ngIf="inputs.length === 1 || !inputs.at(i).get('value')?.value">
                       <img src="/assets/icons/adn/trash-off.svg" alt="Desactivado" class="md-disbursement__icon" />
                     </ng-container>
            </button>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center ">
        <button (click)="addInput()" 
                [disabled]="inputs.length >= maxInputs" 
                class="md-disbursement__body__button "
                [ngClass]="{'btn-desactive': inputs.length >= maxInputs || !inputs.at(0).get('value')?.value}">
                <img [src]="iconPlus" alt="Sumar logro" class="md-disbursement__icon" /> 
                Añadir otro desembolso
        </button>
      </div>
      <div class="d-flex md-disbursement__body__text mt-3" *ngIf="sumResult > 0">
        <img src="/assets/icons/adn/check.svg" alt="Sumar logro" class="md-disbursement__icon mr-1" />
        <p class="t-12-400 font-lato color-dark" >Sumarás <b>S/ {{sumResultString}}</b> a la variable directa <b>“Desembolso Neto”</b></p>
      </div>
      <div class="d-flex md-disbursement__body__text mt-3" *ngIf="sumInRange > 0">
        <img src="/assets/icons/adn/check.svg" alt="Sumar logro" class="md-disbursement__icon mr-1" />
        <p class="t-12-400 font-lato color-dark" >Sumarás <b>S/ {{sumInRangeString}}</b> a tu meta de <b>Bonificación 0 - 20 K</b></p>
      </div>
    </div>
    <div class="md-disbursement__footer mt-3">
      <button 
        type="button" 
        class="md-disbursement__footer__button t-16-700"
         [ngClass]="{'btn-desactived': !inputs.at(0).get('value')?.value, 'btn-active': inputs.at(0).get('value')?.value}"
        [disabled]="!inputs.at(0).get('value')?.value"
        (click)="addAchievement()">Sumar logro</button>
    </div>
  </div>
</form>