import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'milab-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent {
  @Input() order: number = 0;
  @Input() url: string = '' ;
  @Input() icon: string = '';
  @Input() selectedIcon: string = '';
  @Input() title: string = '';
  @Input() isSelected: boolean = false;
  @Input() selectedColor: string = '#434A54';
  @Output() cardClick = new EventEmitter<any>();

  onCardClick(order:number,url:string): void {
    this.cardClick.emit({order:order,url:url});
  }
}
