import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITabItem } from '@shared/interfaces';
import { tabItemDashboard } from '@shared/data/adn/tab.json';
import { AdnAchievement, AdnData, AdnTarget, managementItem, VariableDirect } from '@shared/interfaces/frontend/adn';
import { Subscription } from 'rxjs';
import { AdnCentralizedDataService } from '@shared/services/adn-centralized-data.service';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateBonus, calculateCompliance, 
  calculateComplianceStok, 
  calculateDecelerator, 
  calculateDirectRemuneration, 
  calculateDivision, 
  calculateDivisionInput, 
  calculateFactorAbsence, 
  calculateLiabilitiesManagementCompliance, 
  calculateMoraCompliance, 
  calculatePriceManagementCompliance, 
  calculateResolutionCompliance, 
  calculateWeightedCompliance, 
  complianceColor, 
  convertIntegerToString, 
  convertToNumeric, 
  convertToPercentage, 
  convertToPercentageDecimal, 
  convertToPercentageV2, 
  denominatorDecelerator, 
  divideByHundred, 
  division, 
  formatNumberWithCommas, 
  formatNumberWithCommasNoDecimals, 
  formatPercentageV2,
  overachievementPercentage} from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit, OnDestroy {

  tabItems: ITabItem[] = tabItemDashboard;
  selectedTab!: ITabItem;

  targets: AdnTarget[] = [];
  achievements: AdnAchievement[] = [];
  private subscription: Subscription = new Subscription();
  managementVarCommercial!: managementItem;
  managementVarCollectionResolution!: managementItem;
  managementVarCollectionPise!: managementItem;

  listVariable: VariableDirect[] = [];
  listCollectionVariablesPise9: VariableDirect[] = [];
  listCollectionVariablesResolutions: VariableDirect[] = [];


  metaUpdatedDate!: string;
  workedDays!: string;

  amountDistributed: number= 0 ;
  amountDistributedString!: string ;
  weightedComplianceInitial: number = 0;

  acceleratorPercentInit:number = 0;
  deceleratorPercentInit:number = 0;
  cumpliminetoNeto:number = 0;

  dataDecelarators = {
    metaPaseRec: 0,
    passMonth2: 0,
    passMonth3: 0,
    achievementPassBalance: 0
  };
  dataBonus = {
    amount20k: 0,
    bonus20k: 0
  };
  amountBonus: number = 0;
  gestionPreciosLogro: number = 0;

  pise9Achievement: number = 0;
  resolutionAchievement: number = 0;

  multipliersFinalAutonomy:'sin autonomia' | 'con autonomia' = 'sin autonomia';
  multipliersPassMonth2: number = 0;
  multipliersPassMonth3: number = 0;
  multipliersMetaPaseRec: number = 0;

  calculateBonusAdvance: number  = 0;
  calculateBonusActiveRv: number = 0;
  calculateBonusExceeds80: number= 0;
  calculateBonusAmount20k: number = 0;
  calculateBonusMeta20k:  number = 0;
  calculateBonusAmount: number = 0;
  autonomyData = {
    finalAutonomy: '-',
    month_1_Autonomy: '-',
    month_2_Autonomy: '-',
    month_3_Autonomy: '-'
  }

  finalRvInitial: number = 0;
  factorAucencia: number = 1;
  pasivosMeta:{
    liabilities300: number,
    liabilities400: number
  } = {
    liabilities300: 0,
    liabilities400: 0
  }

  constructor(private centralizedDataService: AdnCentralizedDataService,private adnSimulatorService:AdnSimulatorService) { }

  ngOnInit(): void {
    this.selectedTab = this.tabItems[0];
    this.initSubscription();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleSelectedTab(value: ITabItem): void {
    this.selectedTab = value;
    this.tabItems = tabItemDashboard.map((item) => {
      if (item.label === value.label) {
        return { ...item, active: true };
      } else {
        return { ...item, active: false };
      }
    });
  }

  
  initSubscription(): void {
    this.subscription.add(
      this.centralizedDataService.currentData$.subscribe((data: AdnData | null) => {
        if (data) {
          this.targets = data.targets;
          this.achievements = data.achievements;
          this.createCommercialVariables(this.targets[0], data.achievements[0]);
          this.dataDecelarators.metaPaseRec = data.targets[0].metaPaseRec;
          this.dataDecelarators.passMonth2 = data.targets[0].passMonth2;
          this.dataDecelarators.passMonth3 = data.targets[0].passMonth3;
          this.dataDecelarators.achievementPassBalance = data.achievements[0].achievementPassBalance;
          this.dataBonus.amount20k = data.achievements[0].amount20k;
          this.dataBonus.bonus20k = data.targets[0].bonus20k;
          this.autonomyData.finalAutonomy = data.targets[0].finalAutonomy;
          this.autonomyData.month_1_Autonomy = data.targets[0].flagAutonomyMonth01;
          this.autonomyData.month_2_Autonomy = data.targets[0].autonomyMonth02;
          this.autonomyData.month_3_Autonomy = data.targets[0].autonomyMonth03;
          this.setAchievement(data.achievements[0].achievementPassBalance);
          this.pasivosMeta.liabilities300 = data.targets[0].liabilities300;
          this.pasivosMeta.liabilities400 = data.targets[0].liabilities400;
        }
      })
    );
  } 

  createCommercialVariables(targets: AdnTarget, achievements:AdnAchievement): void {
    const code = 0;
    this.amountDistributed = targets.amountToDistribute;
    this.amountDistributedString = formatNumberWithCommas(targets.amountToDistribute);
    this.metaUpdatedDate =convertIntegerToString(targets.processId);
    this.workedDays = convertIntegerToString(targets.workedDays);
    const managementVarCommerciaTemp :managementItem = { 
          labelOne: 'Tasa real', 
          valueOne: parseFloat(formatPercentageV2(achievements.realRateScde)), 
          labelTwo: 'Tasa óptima', 
          valueTwo: formatNumberWithCommas(achievements.optimalRateScde)
        };
    this.managementVarCommercial = managementVarCommerciaTemp;

    this.gestionPreciosLogro = calculateDivisionInput(achievements.realRateScde,achievements.optimalRateScde);

  /* CALCULO CUMPLIMIETO INIT */
  const dataCumplimiento  = calculateCompliance(convertToNumeric(achievements.netDisbursement),
                                                convertToNumeric(targets.minDisbursement), 
                                                convertToNumeric(targets.disbursement100), 
                                                convertToNumeric(targets.disbursement150), 
                                                convertToNumeric(targets.disbursement200));

  const dataCumplimientoStock =calculateComplianceStok(convertToNumeric(achievements.stockClients), 
                                                       convertToNumeric(targets.minClients),
                                                       convertToNumeric(targets.clients100),
                                                       convertToNumeric(targets.clients150),
                                                       convertToNumeric(targets.clients200));

  const dataCumplimientoPasivo = calculateLiabilitiesManagementCompliance(
                                     convertToNumeric(achievements.openedPasAmount), 
                                     convertToNumeric(targets.minLiabilities), 
                                     convertToNumeric(targets.liabilities100), 
                                     convertToNumeric(targets.liabilities150), 
                                     convertToNumeric(targets.liabilities200), 
                                     convertToNumeric(targets.liabilities300), 
                                     convertToNumeric(targets.liabilities400));
                                     
  const dataCumplimientoGestionPrecios = calculatePriceManagementCompliance(this.gestionPreciosLogro/100, 
                                     targets.minPrices, 
                                     targets.prices100, 
                                     targets.prices125, 
                                     targets.prices150);
                               
    this.cumpliminetoNeto = convertToNumeric(dataCumplimiento);

  this.pise9Achievement = division(achievements.pise9,achievements.balance0To30);            
  const compliancePise9 = calculateMoraCompliance(
                           this.pise9Achievement,
                           targets.minPise9,
                           targets.pise9100,
                           targets.pise9125,
                           targets.pise9150);

  this.resolutionAchievement = division(achievements.balanceResolution0,achievements.balanceCap0);
  const complianceResolution = calculateResolutionCompliance(
                                this.resolutionAchievement,
                                targets.minResolution0,
                                targets.resolution0100,
                                targets.resolution0125,
                                targets.resolution0150)

  this.updateCompliance(convertToNumeric(dataCumplimiento),
                                convertToNumeric(dataCumplimientoStock),
                                convertToNumeric(dataCumplimientoPasivo),
                                convertToNumeric(dataCumplimientoGestionPrecios),
                                convertToNumeric(compliancePise9),
                                convertToNumeric(complianceResolution));
      
  this.weightedComplianceInitial = this.calculateWeightedComplianceInit(convertToNumeric(dataCumplimiento),
   convertToNumeric(dataCumplimientoStock),
   convertToNumeric(dataCumplimientoPasivo),
   convertToNumeric(dataCumplimientoGestionPrecios),
   convertToNumeric(compliancePise9),
   convertToNumeric(complianceResolution));
  
   this.calculateMultipliersDecelerator(targets,achievements);
   this.calculateMultipliersAccelerator(targets.finalAutonomy,this.weightedComplianceInitial);
   this.calculateBonus02K(achievements.amount20k,targets.bonus20k,this.weightedComplianceInitial);
   this.calculateFactorAbsenceInit();
   this.calculateFinalRv(this.weightedComplianceInitial);
   
   /* CALCULO CUMPLIMIETO END */

    const commercialVariables:VariableDirect[] = [
      {
        id:"neto",
        name: "Desembolso Neto (S/)",
        logro:achievements.netDisbursement,
        price: convertToPercentage(targets.disbursementWeight),
        isInput: true,
        cumplimiento: `${dataCumplimiento}%`,
        cumplimientoInit: dataCumplimiento,
        cumplimientoColor: complianceColor(convertToNumeric(achievements.netDisbursement),
                                                convertToNumeric(targets.minDisbursement),
                                                convertToNumeric(targets.disbursement100)),
        metas: {
          headers: ["Mínimo", "100%", "150%", "200%"],
          content: [[
            formatNumberWithCommasNoDecimals(targets.minDisbursement),
            formatNumberWithCommasNoDecimals(targets.disbursement100),
            formatNumberWithCommasNoDecimals(targets.disbursement150),
            formatNumberWithCommasNoDecimals(targets.disbursement200)
          ]]
        },
        increase:''
      },
      {
        id:"stocks",
        name: "Stock de Clientes Activos",
        logro:achievements.stockClients,
        price: convertToPercentage(targets.clientWeight),
        isInput: true,
        cumplimiento: `${dataCumplimientoStock}%`,
        cumplimientoInit: dataCumplimientoStock,
        cumplimientoColor:complianceColor(achievements.stockClients,targets.minClients,targets.clients100),
        metas: {
          headers: ["Mínimo", "100%", "150%", "200%"],
          content: [[
            formatNumberWithCommasNoDecimals(targets.minClients),
            formatNumberWithCommasNoDecimals(targets.clients100),
            formatNumberWithCommasNoDecimals(targets.clients150),
            formatNumberWithCommasNoDecimals(targets.clients200)
          ]]
        },
        increase:''
      },
      {
        id:"pasivos",
        name: "Captación de Pasivos (S/)",
        logro: achievements.openedPasAmount,
        price: convertToPercentage(targets.liabilityWeight),
        isInput: true,
        cumplimiento: `${dataCumplimientoPasivo}%`,
        cumplimientoInit: dataCumplimientoPasivo,
        cumplimientoColor:complianceColor(achievements.openedPasAmount,targets.minLiabilities,targets.liabilities100),
        metas: {
          headers: ["Mínimo", "100%", "150%", "200%"],
          content: [[
            formatNumberWithCommasNoDecimals(targets.minLiabilities),
            formatNumberWithCommasNoDecimals(targets.liabilities100),
            formatNumberWithCommasNoDecimals(targets.liabilities150),
            formatNumberWithCommasNoDecimals(targets.liabilities200)
          ]]
        },
        increase:''
      },
      {
        id:"gestionPrecios",
        name: "Gestión de precios",
        logro: this.gestionPreciosLogro,
        price: convertToPercentage(targets.priceWeight),
        isInput: false,
        cumplimiento: `${dataCumplimientoGestionPrecios}%`,
        cumplimientoInit:dataCumplimientoGestionPrecios,
        cumplimientoColor:complianceColor( this.gestionPreciosLogro/100,targets.minPrices,targets.prices100),
        metas: {
          headers: ["Mínimo", "100%", "125%", "150%"],
          content: [[
            convertToPercentageDecimal(targets.minPrices),
            convertToPercentageDecimal(targets.prices100),
            convertToPercentageDecimal(targets.prices125),
            convertToPercentageDecimal(targets.prices150)
          ]]
        },
        increase:''
      }
    ];

    const managementVarCollectionPiseTemp :managementItem = { 
      labelOne: 'Mora Real > 8 (S/)', 
      valueOne: achievements.pise9, 
      labelTwo: 'Saldo de cartera 0-30 (S/)', 
      valueTwo: formatNumberWithCommas(achievements.balance0To30)
    };
    this.adnSimulatorService.setPise9Mora(achievements.pise9);
    this.managementVarCollectionPise = managementVarCollectionPiseTemp;

    const varCollectionPise:VariableDirect[] = [
      {
        id:"pise9",
        name: "Pise 9 (Mora > 8)",
        logro:parseFloat(convertToPercentageV2(this.pise9Achievement)),
        price: convertToPercentage(targets.pise9Weight),
        isInput: false,
        cumplimiento: `${compliancePise9}%`,
        cumplimientoInit:compliancePise9,
        cumplimientoColor:"green",
        metas: {
          headers: ["Mínimo", "100%", "125%", "150%"],
          content: [[
            convertToPercentageDecimal(targets.minPise9),
            convertToPercentageDecimal(targets.pise9100),
            convertToPercentageDecimal(targets.pise9125),
            convertToPercentageDecimal(targets.pise9150)
          ]]
        },
        increase:''
      }
    ];

    const managementVarCollectionResolutionTemp :managementItem = { 
      labelOne: 'Saldo resuelto 0 días (S/)', 
      valueOne: achievements.balanceResolution0, 
      labelTwo: 'Ola 0 días (S/)', 
      valueTwo: formatNumberWithCommas(achievements.balanceCap0)
    };
    this.adnSimulatorService.setResolutionBalance(achievements.balanceResolution0);
    this.managementVarCollectionResolution = managementVarCollectionResolutionTemp;

    const varCollectionResolution:VariableDirect[] = [
      {
        id:"resolucion",
        name: "Resolución 0 días",
        logro:parseFloat(convertToPercentageV2(this.resolutionAchievement)),
        price: convertToPercentage(targets.resolution0Weight),
        isInput: false,
        cumplimiento: `${complianceResolution}%`,
        cumplimientoInit:complianceResolution,
        cumplimientoColor:"red",
        metas: {
          headers: ["Mínimo", "100%", "125%", "150%"],
          content: [[
            convertToPercentageDecimal(targets.minResolution0),
            convertToPercentageDecimal(targets.resolution0100),
            convertToPercentageDecimal(targets.resolution0125),
            convertToPercentageDecimal(targets.resolution0150)
          ]]
        },
        increase:''
      }
    ];
    this.listCollectionVariablesPise9 = varCollectionPise;
    this.listCollectionVariablesResolutions = varCollectionResolution;
    this.listVariable = commercialVariables;
  }

  updateCompliance(netDisbursement: number, activeClientStock: number, liabilitiesCapture: number, priceManagement: number, pise9Mora: number, resolution0Days: number): void {
    this.adnSimulatorService.setMetrics({
      complianceNetDisbursement: { compliance: netDisbursement, weight: 0.2 },
      complianceActiveClientStock: { compliance: activeClientStock, weight: 0.2 },
      complianceLiabilitiesCapture: { compliance: liabilitiesCapture, weight: 0.1 },
      compliancePriceManagement: { compliance: priceManagement, weight: 0.1 },
      compliancePise9Mora: { compliance: pise9Mora, weight: 0.2 }, 
      complianceResolution0Days: { compliance: resolution0Days, weight: 0.2 },
    });
  }

  calculateWeightedComplianceInit(netDisbursement: number, activeClientStock: number, liabilitiesCapture: number, priceManagement: number, pise9Mora: number, resolution0Days: number): number{
    return netDisbursement*0.2+
    activeClientStock*0.2+
    liabilitiesCapture*0.1+
    priceManagement*0.1+
    pise9Mora*0.2+
    resolution0Days*0.2;
  }

  calculateMultipliersAccelerator(finalAutonomy:string, weightedComplianceInitial:number): void {
    this.multipliersFinalAutonomy = finalAutonomy =='Sin Autonomía Completa consecutiva'?'sin autonomia':'con autonomia';
    this.acceleratorPercentInit = overachievementPercentage(weightedComplianceInitial,this.multipliersFinalAutonomy);
  }
  calculateMultipliersDecelerator(targets: AdnTarget,achievements:AdnAchievement): void {
    const passMonth1 = division(achievements.achievementPassBalance,targets.metaPaseRec);
    this.multipliersMetaPaseRec = targets.metaPaseRec;
    const passSummary = (targets.passMonth3+targets.passMonth2 +passMonth1);
    const baseAverage = calculateDivision(passSummary,denominatorDecelerator(targets.passMonth2,targets.passMonth3))*100;
    this.deceleratorPercentInit = calculateDecelerator(baseAverage);
  }

  calculateBonus02K(amount20k:number,meta20k:number,weightedComplianceInitial:number): void {
    this.adnSimulatorService.setAmount20k(amount20k);
    this.calculateBonusAmount20k= amount20k;
    this.calculateBonusMeta20k= meta20k;
    this.calculateBonusAdvance = calculateDivisionInput(amount20k,meta20k);
    this.calculateBonusActiveRv = weightedComplianceInitial>70?1:0;
    this.calculateBonusExceeds80 = this.calculateBonusAdvance>80?1:0;
    this.calculateBonusAmount= calculateBonus(this.calculateBonusActiveRv,this.calculateBonusExceeds80,this.calculateBonusAmount20k);
  }

  setAchievement(achievementPassBalance: number) {
    this.adnSimulatorService.setAchievementPassBalance(achievementPassBalance);
  }

  calculateFactorAbsenceInit(){
    this.factorAucencia = calculateFactorAbsence(parseFloat(this.workedDays));
  }
  calculateFinalRv(weightedCompliance: number): void {
    const adjustedValue = calculateWeightedCompliance(weightedCompliance);
    const directRemuneration = calculateDirectRemuneration(this.amountDistributed, weightedCompliance, adjustedValue);
    this.finalRvInitial = adjustedValue?(directRemuneration*divideByHundred(this.acceleratorPercentInit)*this.factorAucencia*(1+divideByHundred(this.deceleratorPercentInit))):0;
  }

}

