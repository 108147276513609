<div class="disbursement-input-cm" [ngClass]="{'error-border': error, 'not-error': !error}">
    <div class="disbursement-input-container">
        <label for="disbursement-input-{{dynamicId}}" class="disbursement-input-label" [class.active]="inputFocused || inputValue">
            {{ label }}
        </label>
        <input class="disbursement-input" 
               [type]="type" 
               [value]="inputValue"
               (focus)="onFocus()" 
               (blur)="onBlur()"
               (input)="onInput($event)" 
               id="disbursement-input-{{dynamicId}}" />
        <button *ngIf="isPassword" type="button" class="toggle-visibility-btn" (click)="toggleShowPassword()">
            <img [src]="imgPassword" alt="eye">
        </button>
    </div>
</div>