<div class="md-informative">
    <div class="md-informative__header">
        <h2 class="md-informative__title">¿Cómo se calcula esto?</h2>
        <img 
          src="/assets/icons/adn/close-modal.svg" 
          alt="Cerrar" 
          class="md-informative__close-icon" 
          (click)="activeModal.close()" />
    </div>
    <div class="md-informative__body">
     <h2 class="md-informative__body_title">RV por variables directas</h2>
      <p>El <b>cumplimiento ponderado</b> resulta de la suma ponderada de las variables (cumplimiento de cada variable con su respectivo peso). Se debe alcanzar un valor de 70% para activar la RV.<br><br>
        La <b>RV por variables directas</b> se determinar a partir de la multiplicación del cumplimiento ponderado por el monto a repartir. <br><br>
        Cabe señalar que el cumplimiento se determina a partir del logro de cada variable respecto a la meta.</p>
    </div>
    <div class="md-informative__footer">
      <button 
        type="button" 
        class="md-informative__footer__button t-16-700 font-lato"
        (click)="activeModal.close()">
        Entendido
      </button>
    </div>
  </div>