import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InputItem } from '@shared/interfaces/frontend/adn';
import { formatNumber } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit, OnChanges {
  @Input() item: InputItem = { key: 'defaul', value: 0 };
  value:string='';
  @Output() valueChange = new EventEmitter<InputItem>();

  constructor() { }
  ngOnInit(): void {
    this.value = this.item.value ? formatNumber(this.item.value.toString()): '0';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && changes.item.currentValue.value ) {
      const value = changes.item.currentValue.value;
      this.value = formatNumber(value.toString());
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;
    if (this.item.key === 'stocks') {
      value = value.replace(/[^0-9]/g, '');
    } else {

      if (!/^\d*\.?\d*$/.test(value)) {
        value = value.replace(/[^0-9.]/g, '');  
      }
  
      const parts = value.split('.');
      if (parts.length > 2) {
        value = `${parts[0]}.${parts[1]}`;
      }
  
      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }

    inputElement.value = formatNumber(value);
    this.value = value;
  }

  onBlur() {
    const cleanedValue = this.value.replace(/,/g, '');
    const numericValue = parseFloat(cleanedValue);
    if (!isNaN(numericValue)) {
      this.valueChange.emit({ key: this.item.key, value: numericValue });
    }
  }

}
