import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'milab-increase-card',
  templateUrl: './increase-card.component.html',
  styleUrls: ['./increase-card.component.scss']
})
export class IncreaseCardComponent implements OnInit {
  @Input() increase: string = '';
  @Input() icon: string = '/assets/icons/adn/arrow-narrow-down-green.svg';
  @Input() color: string = 'c-increment';
  colorClass: string = '';
  ngOnInit(): void {

    }
}

