import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'milab-modal-reset',
  templateUrl: './modal-reset.component.html',
  styleUrls: ['./modal-reset.component.scss']
})
export class ModalResetComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
