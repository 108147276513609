<section class="accelerators">
  <h2 class="accelerators__title">Sobrecumplimiento</h2>
  <div class="row">
    <div class="col-12 col-md-5">
      <div class="overcompliance card">
        <p class="t-14-400">Acelerador por Sobrecumplimiento</p>
        <h2 class="t-24-700">{{ overcomplianceIndicator }}</h2>
        <p class="t-12-400">
          Cumplimiento ponderado: <strong class="t-14-700">{{ overcomplianceWeighted }}</strong>
          <img class="mb-1 ml-1" [src]="imgComplianceWeight" width="16" height="16" alt="" />
        </p>
        <p class="t-12-400">
          Tipo de autonomía: <strong class="t-14-700">{{ autonomyData.finalAutonomy }}</strong>
        </p>
      </div>
      <!--  MOBILE  -->
      <div class="compliance d-block d-md-none">
        <div class="row compliance__header align-items-center m-0">
          <div class="col-4 t-14-700 text-center m-0 px-2">Cumplimiento ponderado</div>
          <div class="col-4 t-14-700 text-center m-0 px-2">Sin Autonomía Completa Consecutiva</div>
          <div class="col-4 t-14-700 text-center m-0 px-2">Con Autonomía Completa Consecutiva</div>
        </div>
        <div class="compliance__body">
          <div
            class="compliance__body-item row align-items-center m-0 p-2"
            *ngFor="let item of acceleratorsTable"
            [ngClass]="{ selected: item.selected }"
          >
            <div class="col-4 t-14-400 text-center m-0 px-0">
              {{ item.c11 }} <img src="assets/icons/adn/arrow-right-dark.svg" width="16" height="16" alt="" />
              {{ item.c12 }}
            </div>
            <div
              class="col-4 t-14-400 text-center m-0 px-0"
              [ngClass]="{ selectedChild: item.selected && autonomyData.finalAutonomy.includes('Sin') }"
            >
              {{ item.c2 }}
            </div>
            <div
              class="col-4 t-14-400 text-center m-0 px-0"
              [ngClass]="{ selectedChild: item.selected && autonomyData.finalAutonomy.includes('Con') }"
            >
              {{ item.c3 }}
            </div>
          </div>
        </div>
      </div>
      <!-- ************* -->
      <div class="autonomy card">
        <p class="t-12-400">Tipo de autonomía (3 meses consecutivos)</p>
        <p class="autonomy__t-turquoise t-16-700">{{ autonomyData.finalAutonomy }}</p>
        <div class="row autonomy__grid">
          <div
            class="col-4 autonomy__box pl-1rem pr-2"
            *ngFor="let monthData of lastThreeMonthsData"
            [ngClass]="{ 'b-turquoise': monthData.autonomyStatus === 'Autonomia Completa' }"
          >
            <p
              [ngClass]="{
                't-12-700': monthData.autonomyStatus === 'Autonomia Completa',
                'autonomy__t-gray t-12-400': monthData.autonomyStatus !== 'Autonomia Completa'
              }"
            >
              {{ monthData.monthName | titlecase }}. {{ monthData.year }}
            </p>
            <p
              [ngClass]="{
                'autonomy__t-turquoise t-14-700': monthData.autonomyStatus === 'Autonomia Completa',
                'autonomy__t-dark t-14-400': monthData.autonomyStatus !== 'Autonomia Completa'
              }"
            >
              {{ monthData.autonomyStatus }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 d-none d-md-block">
      <!-- DESKTOP -->
      <div class="compliance">
        <div class="row compliance__header align-items-center m-0">
          <div class="col-4 t-14-700 text-center m-0 px-2">Cumplimiento ponderado</div>
          <div class="col-4 t-14-700 text-center m-0 px-2">Sin Autonomía Completa Consecutiva</div>
          <div class="col-4 t-14-700 text-center m-0 px-2">Con Autonomía Completa Consecutiva</div>
        </div>
        <div class="compliance__body">
          <div
            class="compliance__body-item row align-items-center m-0 p-2"
            *ngFor="let item of acceleratorsTable"
            [ngClass]="{ selected: item.selected }"
          >
            <div class="col-4 t-14-400 text-center m-0 px-0">
              {{ item.c11 }} <img src="assets/icons/adn/arrow-right-dark.svg" width="16" height="16" alt="" />
              {{ item.c12 }}
            </div>
            <div
              class="col-4 t-14-400 text-center m-0 px-0"
              [ngClass]="{ selectedChild: item.selected && autonomyData.finalAutonomy.includes('Sin') }"
            >
              {{ item.c2 }}
            </div>
            <div
              class="col-4 t-14-400 text-center m-0 px-0"
              [ngClass]="{ selectedChild: item.selected && autonomyData.finalAutonomy.includes('Con') }"
            >
              {{ item.c3 }}
            </div>
          </div>
        </div>
      </div>
      <!-- ************* -->
    </div>
  </div>
</section>
