import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdnUserProfile } from '@shared/models/UserProfileModel';

@Component({
  selector: 'milab-adn-header',
  templateUrl: './adn-header.component.html',
  styleUrls: ['./adn-header.component.scss']
})
export class AdnHeaderComponent implements OnInit {
  @Input() nameAdn:string = 'MEJIA OBREGON MARIA ELENE';
  @Input() codeAdn:string = '12539';
  @Input() user!: AdnUserProfile;
  @Output() activedSidebar = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  sidebar(){
    this.activedSidebar.emit(true); 
  }
}
