import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';
import { SharedModule } from '@shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponentModule } from './layout/header/header-component.module';
import { LayoutComponent } from './layout/layout.component';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';

import * as Sentry from '@sentry/angular';
import { Router, RouterModule } from '@angular/router';
import { ErrorLogger } from '../../error.logger';
import { HttpProgressInterceptor } from '@shared/interceptors/progress.interceptor';
import { FooterModule } from './layout/footer/footer.module';
import { TokenInterceptor } from '@shared/interceptors/token.interceptor';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '@environments/environment';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NewIntranetModule } from './new-intranet/new-intranet.module';
import { BlogService } from '@shared/services/blog.service';
import { ApiInterceptor } from '@shared/interceptors/blogApi.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlliedModule } from './allied/allied.module';
import { AdnIntranetModule } from './adn/adn-intranet/adn-intranet.module';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    IconSpriteModule.forRoot({ path: 'assets/sprites/sprite.svg' }),
    AppRoutingModule,
    NgbModule,
    HeaderComponentModule,
    FooterModule,
    NgxJsonLdModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    SharedModule,
    RouterModule,
    RecaptchaV3Module,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NewIntranetModule,
    BrowserAnimationsModule,
    AlliedModule,
    AdnIntranetModule,
  ],
  providers: [
    Title,
    Meta,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: ErrorHandler,
      useClass: ErrorLogger,
      deps: [],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): any => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    BlogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
