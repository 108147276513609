<div class="content-variable">
  <div class="content-variable_action">
    <div class="content-variable_action__title">RV por variables directas</div>
    <a class="p-link" (click)="openModal()">¿Cómo se calcula?</a>
  </div>
  <div >
    <div class="content-variable__content mb-3">
      <div class="content-variable__content__card b-solid">
        <p class="content-variable__content__card_title t-14-400 font-lato color-dark">Cumplimiento ponderado</p>
        <div class="content-variable__content__card__value t-18-700 color-dark mt-2">{{weightedCompliance.toFixed(2)}}% <img [src]="imgComplianceWeight" alt="complies-ompliance"></div>
        <div class="increase-wrapper" *ngIf="increaseCompliance.length > 0">
            <milab-increase-card 
              [increase]="increaseCompliance"
              [icon]="iconCompliance"
              [color]="complianceColor">
            </milab-increase-card>
        </div>
      </div>

      <div class="content-variable__content__card b-solid">
        <p class="content-variable__content__card_title t-14-400 font-lato color-dark">RV Variables  directas</p>
        <div class="content-variable__content__card__value t-18-700 color-dark mt-2"  >S/ {{directRemunerationValue.toFixed(2)}}</div>
        <div class="increase-wrapper" *ngIf="increaseDistributed.length > 0">
          <milab-increase-card 
            [increase]="increaseDistributed"
            [icon]="iconDistributede"
            [color]="distributedColor">
          </milab-increase-card>
        </div>
      </div>

    </div>
    <div class="content-variable__btn">
      <button class="content-variable__btn__button" (click)="newAmount()">
        <img src="/assets/icons/adn/plus-white.svg"> Nuevo desembolso</button>
    </div>
    
  </div>
</div>

<div class="section-variable-card pd-0-20 d-none d-md-block">
  <milab-variables-card 
    [listVariable]="listVariable" 
    variableItem="Variable comerciales"
    [managementItem]="managementVarCommercial"
    [amount]="amount">
  </milab-variables-card>
</div>

<div class="section-variable-card mt-3 pd-0-20 d-none d-md-block">
  <milab-variable-collection-card 
  [listVariablePise9]="listCollectionVariablesPise9"
  [listVariableResolutions]="listCollectionVariablesResolutions" 
  [managementItem]=" managementVarCollectionPise"
  [managementItemTwo]="managementVarCollectionResolution"
  variableItem="Variable de cobranza">
  </milab-variable-collection-card>
</div>

<div class="variables-card-mobile d-block d-lg-none">
  <milab-tab-options
    id="tap-taxis"
    tabNameOne="Var. Comerciales"
    tabNameTwo="Var. de Cobranza"
   (tabChangeButtonEvent)="tabOptions($event)">
</milab-tab-options>

  <div *ngIf="isVarComercer">
    <milab-variable-card-mobile 
      [listVariable]="gestionPreciosList"
      [amount]="amount">
    </milab-variable-card-mobile>

    <div>
      <milab-variable-collection-card-mobile 
        [variable]="listVariable[3]" 
        [managementItem]="managementVarCommercial">
      </milab-variable-collection-card-mobile>
    </div>
  </div>

  <div *ngIf="!isVarComercer">
    <div >
      <milab-variable-collection-card-mobile
        [variable]="listCollectionVariablesPise9[0]"
        [managementItem]="managementVarCollectionPise">
      </milab-variable-collection-card-mobile>
    </div>
    <div>
      <milab-variable-collection-card-mobile
        [variable]="listCollectionVariablesResolutions[0]"
        [managementItem]="managementVarCollectionResolution">
      </milab-variable-collection-card-mobile>
    </div>
  </div>
</div>