import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AdnDashboardComponent } from './adn-dashboard/adn-dashboard.component';
import { SimulatorComponent } from './pages/simulator/simulator.component';
import { CommonModule } from "@angular/common";
import { AdnIntranetRoutingModule } from "./adn-intranet-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { CumplirmeCardComponent } from './components/cumplirme-card/cumplirme-card.component';
import { SimulatorTabsComponent } from './components/simulator-tabs/simulator-tabs.component';
import { NewsComponent } from './pages/news/news.component';
import { AdnHeaderComponent } from './components/adn-header/adn-header.component';
import { SectionInfoComponent } from './components/section-info/section-info.component';
import { SectionDirectVariablesComponent } from './components/section-direct-variables/section-direct-variables.component';
import { SectionAcceleratorsComponent } from './components/section-accelerators/section-accelerators.component';
import { SectionDeceleratorsComponent } from './components/section-decelerators/section-decelerators.component';
import { SectionBonusComponent } from './components/section-bonus/section-bonus.component';
import { SectionBlurComponent } from './components/section-blur/section-blur.component';
import { CustomerTableComponent } from './components/customer-table/customer-table.component';
import { CommingSoonComponent } from './components/comming-soon/comming-soon.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { IncreaseCardComponent } from './components/increase-card/increase-card.component';
import { VariablesCardComponent } from './components/variables-card/variables-card.component';
import { MultipliersCardComponent } from './components/multipliers-card/multipliers-card.component';
import { SummarySimulatorCardComponent } from './components/summary-simulator-card/summary-simulator-card.component';
import { ButtonCustomerComponent } from './components/button-customer/button-customer.component';
import { CollectionVariablesCardComponent } from './components/collection-variables-card/collection-variables-card.component';
import { ManagementCollectionsCardComponent } from './components/management-collections-card/management-collections-card.component';
import { ModalInformativeComponent } from './components/modal-informative/modal-informative.component';
import { ModalSummaryComponent } from './components/modal-summary/modal-summary.component';
import { ModalDisbursementComponent } from './components/modal-disbursement/modal-disbursement.component';
import { TabOptionsComponent } from './components/tab-options/tab-options.component';
import { InvertedTableComponent } from './components/inverted-table/inverted-table.component';
import { DisbursementInputComponent } from './components/disbursement-input/disbursement-input.component';
import { VariableCollectionCardComponent } from './components/variable-collection-card/variable-collection-card.component';
import { VariableCardMobileComponent } from './components/variable-card-mobile/variable-card-mobile.component';
import { VariableCollectionCardMobileComponent } from './components/variable-collection-card-mobile/variable-collection-card-mobile.component';
import { NumericmaskDirective } from './directives/numericmask.directive';
import { AmountNumericFormatterPipe } from './pipes/amount-numeric-formatter.pipe';
import { BonusCardComponent } from './components/bonus-card/bonus-card.component';
import { ModalResetComponent } from './components/modal-reset/modal-reset.component';
@NgModule({
    declarations: [
      AdnDashboardComponent, 
      SimulatorComponent, 
      SidebarComponent, 
      CardListComponent, 
      CumplirmeCardComponent, 
      SimulatorTabsComponent, 
      NewsComponent, 
      AdnHeaderComponent, 
      SectionInfoComponent, 
      SectionDirectVariablesComponent, 
      SectionAcceleratorsComponent, 
      SectionDeceleratorsComponent, 
      SectionBonusComponent, 
      SectionBlurComponent, 
      CustomerTableComponent,
      CommingSoonComponent,
      NumberInputComponent,
      IncreaseCardComponent,
      VariablesCardComponent,
      MultipliersCardComponent,
      SummarySimulatorCardComponent,
      ButtonCustomerComponent,
      CollectionVariablesCardComponent,
      ManagementCollectionsCardComponent,
      ModalInformativeComponent,
      ModalSummaryComponent,
      ModalDisbursementComponent,
      TabOptionsComponent,
      InvertedTableComponent,
      DisbursementInputComponent,
      VariableCollectionCardComponent,
      VariableCardMobileComponent,
      VariableCollectionCardMobileComponent,
      NumericmaskDirective,
      AmountNumericFormatterPipe,
      BonusCardComponent,
      ModalResetComponent],
    imports: [CommonModule,
      AdnIntranetRoutingModule,
      FormsModule,
      NgbModalModule,
      NgbModule,
      ReactiveFormsModule],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  })
  export class AdnIntranetModule {}
  