<div class="card-collection-mobile mt-3">
    <div class="card-collection-mobile__header d-flex justify-content-between mt-1 mb-2">
      <div class="t-16-700 font-lato-dark">{{variable.name}}</div>
      <div class="t-14-400 font-lato-dark">Peso: {{variable.price}}</div>
    </div>
    <div class="card-collection-mobile__body mb-3">
      <div class="d-flex justify-content-between mb-2">
        <div class="t-16-400 font-lato-dark">Logro:</div>
        <div class="t-16-700 font-lato-dark">{{variable.logro}}%</div>
      </div>
  
      <div class="d-flex justify-content-between mb-2">
        <div class="t-16-400 font-lato-dark">Cumplimiento:</div>
        <div class="t-14-400 font-lato" [ngClass]="variable.cumplimientoColor">
          <div class="vertical-stack">
            {{ variable.cumplimiento }}
            <div class="increase-wrapper mf-5px" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card 
                 [increase]="variable.increase"
                 [icon]="variable.increaseIcon"
                 [color]="variable.increaseColor">
              </milab-increase-card>
            </div>
          </div>
        </div>
      </div>
      
      <div class="card-collection-mini">
        <div class="d-flex  justify-content-between mb-2">
          <div class="t-14-700 font-lato-dark">{{managementItem.labelOne}}</div>
          <div class="w-white w-115 ">
                <milab-number-input 
                   class=" w-120" 
                   [item]="{ key: variable.id, value: managementItem.valueOne}"
                   (valueChange)="onInputChange($event)">
               </milab-number-input>
              </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div class="t-14-700 font-lato-dark">{{managementItem.labelTwo}}</div>
          <div class="t-14-700 font-lato-dark">{{managementItem.valueTwo}}</div>
        </div>
      </div>

      </div>
    <div class="card-collection-mobile__footer mb-2">
      <div class="d-flex  justify-content-between" (click)="activeTable()">
        <div class="t-16-700 font-lato-dark">Metas a lograr</div>
        <img src="/assets/icons/adn/chevron-down.svg" alt="chevron-down" [ngClass]="{'rotate-180': isRotated}">
      </div>
      <div *ngIf="isTable"> 
          <milab-inverted-table
            [id]="variable.id"
            [headers]="variable.metas.headers"
            [content]="variable.metas.content">
          </milab-inverted-table>
      </div>
    </div>
  </div>