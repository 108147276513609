<div class="collection-card">
    <div class="collection-card__container w-700">
      <div class="collection-card__container_item">{{itemLabelOne}}:</div>
      <div class="collection-card__container_item bg-white br-8 ">
        <milab-number-input 
           class="card-number-input w-120" 
           [item]="item"
           (valueChange)="onValueChangeManagement($event)">
        </milab-number-input>
      </div>
      <div class="collection-card__container_item">{{itemLabelTwo}}:</div>
      <div class="collection-card__container_item">{{ valueTwo }}</div>
    </div>
  </div>