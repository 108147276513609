import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'milab-inverted-table',
  templateUrl: './inverted-table.component.html',
  styleUrls: ['./inverted-table.component.scss']
})
export class InvertedTableComponent implements OnInit {
  @Input() headers: string[] =  ["Mínimo", "100%", "150%", "200%"];
  @Input() content: string[][]=  [["169,00"], ["281,700"], ["386,100"], ["497,000"]];
  
  constructor() { }

  ngOnInit(): void {
    this.content = this.transformContent(this.content);
  }

  transformContent(content: string[][]): string[][] {
    const transformed: string[][] = [];
    if (content.length > 0) {
      const firstRow = content[0];
      firstRow.forEach(value => transformed.push([value]));
    }
    return transformed;
  }

}
