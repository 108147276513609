import { Component, Input, OnInit } from '@angular/core';
import { INCREASE } from '@shared/constants/adn-simulator.constants';
import { ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateWeightedCompliance, formatNumberWithCommasNoDecimals, formatPercentage } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-section-bonus',
  templateUrl: './section-bonus.component.html',
  styleUrls: ['./section-bonus.component.scss'],
})
export class SectionBonusComponent implements OnInit {
  achievement: number = 0;
  compliance: number = 0;

  validCompliance = {
    txt: 'No',
    img: '/assets/icons/adn/x-close.svg',
    valid: false,
  };
  validBonus = {
    txt: 'No',
    img: '/assets/icons/adn/x-close.svg',
    valid: false,
  };

  amountBonus: number = 0;

  @Input() dataBonus = {
    amount20k: 0,
    bonus20k: 0,
  };

  variable = {
    cumplimientoInit: 0,
    increase: '',
    increaseColor: '',
    increaseIcon: '',
  };
  amountVariable = {
    cumplimientoInit: 0,
    increase: '',
    increaseColor: '',
    increaseIcon: '',
  };
  constructor(private readonly adnSimulatorService: AdnSimulatorService) {
    this.adnSimulatorService.metrics$.subscribe((value) => {
      const ponderado = this.calculateWeightedComplianceSync(value);
      calculateWeightedCompliance(ponderado)
        ? ((this.validCompliance.txt = 'Sí'),
          (this.validCompliance.img = '/assets/icons/adn/check-circle.svg'),
          (this.validCompliance.valid = true))
        : ((this.validCompliance.txt = 'No'),
          (this.validCompliance.img = '/assets/icons/adn/x-close.svg'),
          (this.validCompliance.valid = false));
    });
  }

  calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
    return (
      metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
      metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
      metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
      metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight +
      metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
      metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight
    );
  }

  ngOnInit(): void {
    this.achievement = this.dataBonus.amount20k;
    this.variable.cumplimientoInit = (this.achievement / this.dataBonus.bonus20k) * 100;
    this.getBonus();
    this.calculateAmountBonus();
    this.amountVariable.cumplimientoInit = this.amountBonus;
  }

  setAchievement(event: any, fromDom: boolean = true) {
    if (!fromDom) {
      this.achievement = event;
    } else {
      const value = Number.parseInt(event.target.value.replace(/,/g, ''));
      this.achievement = isNaN(value) ? 0 : value;
    }
    this.compliance = (this.achievement / this.dataBonus.bonus20k) * 100;
    if (this.compliance >= 80) {
      this.validBonus.txt = 'Sí';
      this.validBonus.img = '/assets/icons/adn/check-circle.svg';
      this.validBonus.valid = true;
    } else {
      this.validBonus.txt = 'No';
      this.validBonus.img = '/assets/icons/adn/x-close.svg';
      this.validBonus.valid = false;
    }
    this.calculateAmountBonus();
    this.updateIncrease(this.compliance);
  }
  onBlurSetAchievement() {
    this.adnSimulatorService.setAmount20k(this.achievement);
  }
  calculateAmountBonus() {
    const tmpAmount =
      ((this.validCompliance.valid ? 1 : 0) * (this.validBonus.valid ? 1 : 0) * this.compliance * 300) / 100;
    this.amountBonus = tmpAmount < 450 ? tmpAmount : 450;
    this.calculeIncrementedRemuneration(this.amountBonus);
  }
  getBonus() {
    this.adnSimulatorService.amount20kSubject$.subscribe((data) => {
      this.setAchievement(data, false);
    });
  }

  private updateIncrease(dataCumplimiento: number): void {
    const increase = dataCumplimiento - this.variable.cumplimientoInit;
    if (increase > 0) {
      this.variable.increase = formatPercentage(increase);
      this.variable.increaseColor = INCREASE.colorIncrement;
      this.variable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increase < 0) {
      this.variable.increase = formatPercentage(increase);
      this.variable.increaseColor = INCREASE.colorDecrement;
      this.variable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      this.variable.increase = '';
    }
  }

  calculeIncrementedRemuneration(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.amountVariable.cumplimientoInit;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.amountVariable.increase = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.amountVariable.increaseColor = INCREASE.colorIncrement;
      this.amountVariable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.amountVariable.increase = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.amountVariable.increaseColor = INCREASE.colorDecrement;
      this.amountVariable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      this.amountVariable.increase = '';
    }
  }
}
